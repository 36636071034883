import { Box, Text } from "@chakra-ui/react";

const Team = () => {
  return (
    <Box h="100vh" w="100%" bg="yellow.400" p={5}>
      <Text textAlign={"center"}>Team</Text>
    </Box>
  );
};

export default Team;
